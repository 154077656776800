// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Gilroy", sans-serif;
  --content-width: 1290px;
  --container-offset: 30px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #fff;
}
