.launch-page {
    min-height: 100vh;
    width: 100vw;

    position: relative;

    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background: linear-gradient(103deg, #FF0231 0%, #921743 52.67%, #292C55 100%);
    }

    @media (max-width: 768px) {        
        padding: 30px 15px;
    }
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
}

.page {
    &-wrapper {
        min-height: 100vh;
        position: relative;
        z-index: 5;

        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #FFF;

        @media (max-width: 768px) {
            min-height: calc(100vh - 60px);
        }
    }

    &-logo {
        width: 83px;
        height: 83px;
        margin-bottom: 35px;
    }

    &-subtext {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 90%; /* 14.4px */
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    &-title {
        display: flex;
        align-items: center;
        gap: 13px;
        margin-bottom: 20px;

        @media (max-width: 768px) {
            margin-bottom: 30px;
        }

        &__text {
            text-align: center;
            font-size: 64px;
            font-style: normal;
            font-weight: 600;
            line-height: 90%; /* 57.6px */
            text-transform: uppercase;

            @media (max-width: 768px) {
                font-size: 48px;
            }
        }

        &__icon {
            @media (max-width: 768px) {
                height: 54px;
                width: 67px;
            }
        }
    }

    &-text {
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 30px;

        @media (max-width: 768px) {
            margin-bottom: 35px;
        }
    }

    &-contacts {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-bottom: 15px;

        @media (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
            gap: 15px;
        }
    }

    &-contact {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 22px;
        }

        span {
            opacity: 0.5;
        }

        a {
            color: #fff;
            transition: opacity 0.3s ease-out;

            @media (min-width: 769px) {
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    &-btns {
        margin-top: 30px;

        display: flex;
        align-items: center;
        gap: 30px;

        @media (max-width: 768px) {
            margin-top: 40px;
            flex-direction: column;
            gap: 10px;
            width: 100%;
            padding: 0 15px;
        }
    }

    &-btn {
        border-radius: 5px;
        border: 1px solid #FFF;
        background-color: transparent;

        width: 182px;
        height: 123px;   
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 10px 15px;

        transition: background-color 0.3s ease-out;         

        @media (max-width: 768px) {
            background-color: #fff;
            max-width: 100%;
            width: 100%;
            height: 111px;
        }

        @media (min-width: 769px) {
            &:hover {
                background-color: #fff;
    
                .page-btn__icon {
                    fill: #000;
                }
    
                .page-btn__text {
                    color: #000;
                }
            }
        }

        &__icon {
            fill: #fff;
            transition: fill 0.3s ease-out;
            flex-shrink: 0;

            @media (max-width: 768px) {
                fill: #000;
            }
        }

        &__text {
            color: #fff;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            transition: color 0.3s ease-out;

            @media (max-width: 768px) {
                color: #000;
            }
        }
    }
}
