:root {
  --font-family: "Gilroy", sans-serif;
  --content-width: 1290px;
  --container-offset: 30px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #fff;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}
a:focus-visible {
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}
@media (max-width: 768px) {
  .container {
    padding: 0 15px;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.launch-page {
  min-height: 100vh;
  width: 100vw;
  position: relative;
}
.launch-page::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(103deg, #FF0231 0%, #921743 52.67%, #292C55 100%);
}
@media (max-width: 768px) {
  .launch-page {
    padding: 30px 15px;
  }
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
}

.page-wrapper {
  min-height: 100vh;
  position: relative;
  z-index: 5;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFF;
}
@media (max-width: 768px) {
  .page-wrapper {
    min-height: calc(100vh - 60px);
  }
}
.page-logo {
  width: 83px;
  height: 83px;
  margin-bottom: 35px;
}
.page-subtext {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 90%; /* 14.4px */
  text-transform: uppercase;
  margin-bottom: 15px;
}
.page-title {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .page-title {
    margin-bottom: 30px;
  }
}
.page-title__text {
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 90%; /* 57.6px */
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .page-title__text {
    font-size: 48px;
  }
}
@media (max-width: 768px) {
  .page-title__icon {
    height: 54px;
    width: 67px;
  }
}
.page-text {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .page-text {
    margin-bottom: 35px;
  }
}
.page-contacts {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  .page-contacts {
    flex-direction: column;
    justify-content: center;
    gap: 15px;
  }
}
.page-contact {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
}
@media (max-width: 768px) {
  .page-contact {
    font-size: 22px;
  }
}
.page-contact span {
  opacity: 0.5;
}
.page-contact a {
  color: #fff;
  transition: opacity 0.3s ease-out;
}
@media (min-width: 769px) {
  .page-contact a:hover {
    opacity: 0.8;
  }
}
.page-btns {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 768px) {
  .page-btns {
    margin-top: 40px;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 0 15px;
  }
}
.page-btn {
  border-radius: 5px;
  border: 1px solid #FFF;
  background-color: transparent;
  width: 182px;
  height: 123px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 15px;
  transition: background-color 0.3s ease-out;
}
@media (max-width: 768px) {
  .page-btn {
    background-color: #fff;
    max-width: 100%;
    width: 100%;
    height: 111px;
  }
}
@media (min-width: 769px) {
  .page-btn:hover {
    background-color: #fff;
  }
  .page-btn:hover .page-btn__icon {
    fill: #000;
  }
  .page-btn:hover .page-btn__text {
    color: #000;
  }
}
.page-btn__icon {
  fill: #fff;
  transition: fill 0.3s ease-out;
  flex-shrink: 0;
}
@media (max-width: 768px) {
  .page-btn__icon {
    fill: #000;
  }
}
.page-btn__text {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: color 0.3s ease-out;
}
@media (max-width: 768px) {
  .page-btn__text {
    color: #000;
  }
}